import {
  modifyDataDeliveryGroup,
  receiveDataDeliveryGroup,
  setDataDeliveryGroupIsBusy,
  closeDataDeliveryGroup
} from './delivery-group.actions';
import itemReducer from '../../../items/item.reducers';

const deliveryGroupReducer = itemReducer(
  receiveDataDeliveryGroup,
  setDataDeliveryGroupIsBusy,
  modifyDataDeliveryGroup,
  closeDataDeliveryGroup
);

export default deliveryGroupReducer;
