import {
  modifyCampaign,
  receiveCampaign,
  setCampaignIsBusy,
  closeCampaign
} from './campaign.actions';
import itemReducer from '../../items/item.reducers';

const campaignReducer = itemReducer(
  receiveCampaign,
  setCampaignIsBusy,
  modifyCampaign,
  closeCampaign
);

export default campaignReducer;
