import {
  modifyAdvertiser,
  receiveAdvertiser,
  setAdvertiserIsBusy,
  closeAdvertiser
} from './advertiser.actions';
import itemReducer from '../items/item.reducers';

const advertiserReducer = itemReducer(
  receiveAdvertiser,
  setAdvertiserIsBusy,
  modifyAdvertiser,
  closeAdvertiser
);

export default advertiserReducer;
