import {
  modifySurvey,
  receiveSurvey,
  setSurveyIsBusy,
  closeSurvey
} from './survey.actions';
import itemReducer from '../items/item.reducers';

const surveyReducer = itemReducer(
  receiveSurvey,
  setSurveyIsBusy,
  modifySurvey,
  closeSurvey
);

export default surveyReducer;
