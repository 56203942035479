import {
  modifyCampaignPlacement,
  receiveCampaignPlacement,
  setCampaignPlacementIsBusy,
  closeCampaignPlacement
} from './campaign-placement.actions';
import itemReducer from '../../items/item.reducers';

const campaignPlacementReducer = itemReducer(
  receiveCampaignPlacement,
  setCampaignPlacementIsBusy,
  modifyCampaignPlacement,
  closeCampaignPlacement
);

export default campaignPlacementReducer;
