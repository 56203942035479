import {
  modifyTrafficPartner,
  receiveTrafficPartner,
  setTrafficPartnerIsBusy,
  closeTrafficPartner
} from './traffic-partner.actions';
import itemReducer from '../../items/item.reducers';

const trafficPartnerReducer = itemReducer(
  receiveTrafficPartner,
  setTrafficPartnerIsBusy,
  modifyTrafficPartner,
  closeTrafficPartner
);

export default trafficPartnerReducer;
