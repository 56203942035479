import * as nodeTypes from './node-types';

export const dropPositions = {
  BEFORE: 'BEFORE',
  AFTER: 'AFTER',
  INTO: 'INTO',
  BACK_MAGIC: 'BACK_MAGIC'
};

export const canDropNode = (targetNode, nodeToDrop, dropPosition) => {
  switch (targetNode.type) {
    case nodeTypes.START:
      return dropPosition === dropPositions.AFTER;
    case nodeTypes.STOP:
      return dropPosition === dropPositions.BEFORE;
    case nodeTypes.PAGE:
      if (targetNode?.goToNodeType) {
        return (
          dropPosition === dropPositions.BEFORE ||
          dropPosition === dropPositions.INTO
        );
      }
      return true;
    default:
      return true;
  }
};

export const getEventTrackingAttr = node => {
  return Array.isArray(node.args) && node.args.length ? node.args[0] : null;
};
