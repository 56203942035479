import { createAction } from '@reduxjs/toolkit';
import { cloneDeep, isEqual } from 'lodash';
import * as itemTypes from '../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../items/item.actions';
import {
  conditionTypes,
  dropPositions,
  booleanConditionModel,
  targetIndices,
  pastEventConditionModel,
  APPLICABLE_EVENT_TYPES
} from './audience.constants';
import { v4 as uuidv4 } from 'uuid';
import {
  platforms,
  isApplicable
} from '../environment-config/environment-config';
import {
  buildSourceIdRuleCondition,
  getSourceIdDisplayCondition
} from '../rules/rule.constants';

export const receiveAudience = createAction('receiveAudience');
export const setAudienceIsBusy = createAction('setAudienceIsBusy');
export const modifyAudience = createAction('modifyAudience');
export const closeAudience = createAction('closeAudience');

const audienceModel = (id, name, params) => {
  const model = {
    id,
    name,
    type: itemTypes.AUDIENCE,
    segment: booleanConditionModel()
  };

  if (isApplicable({ platform: platforms.CDP })) {
    model.targetIndex = targetIndices.PROFILE; //only allow profile audiences on cdp
  }

  return model;
};

export const getQueryCondition = item => {
  const conditionCopy = cloneDeep(item.segment);
  delete conditionCopy._id;

  const conditionsCopy = cloneConditions(
    conditionCopy.parameterValues.subConditions
  );
  convertConditions(conditionsCopy, false);
  conditionCopy.parameterValues.subConditions = conditionsCopy;

  //wrap the boolean condition in a pastEventCondition
  if (item.targetIndex === targetIndices.EVENT) {
    const pastEventCondition = pastEventConditionModel(
      item.numberOfDays,
      item.minimumEventCount,
      item.maximumEventCount,
      APPLICABLE_EVENT_TYPES.find(et => et.value === item.pastEventType)
    );
    delete pastEventCondition._id;
    delete pastEventCondition.parameterValues.eventCondition._id;

    if (conditionCopy.parameterValues.subConditions.length > 0) {
      pastEventCondition.parameterValues.eventCondition.parameterValues.subConditions.push(
        conditionCopy
      );
    }

    return pastEventCondition;
  }

  return conditionCopy;
};

export const getUIValue = item => {
  let pastEventType;
  item.segment._id = uuidv4();

  if (item.targetIndex === targetIndices.EVENT) {
    let minimumEventCount = item.segment.parameterValues.minimumEventCount;
    let maximumEventCount = item.segment.parameterValues.maximumEventCount;
    let numberOfDays = item.segment.parameterValues.numberOfDays;

    //eventTypeCondition is always the first subCondition
    const eventTypeCondition =
      item.segment.parameterValues?.eventCondition?.parameterValues
        .subConditions[0];
    if (eventTypeCondition) {
      pastEventType = APPLICABLE_EVENT_TYPES.find(et =>
        isEqual(et.condition, eventTypeCondition)
      );
    }

    //additionalFilters are always the second subCondition, if there were any defined
    let booleanCondition =
      item.segment.parameterValues?.eventCondition?.parameterValues
        .subConditions[1];

    if (!booleanCondition) {
      booleanCondition = booleanConditionModel();
    }
    booleanCondition._id = uuidv4();
    convertConditions(booleanCondition.parameterValues.subConditions, true);

    return {
      segment: booleanCondition,
      minimumEventCount,
      maximumEventCount,
      numberOfDays,
      pastEventType: pastEventType?.value
    };
  }

  convertConditions(item.segment.parameterValues.subConditions, true);

  return {
    segment: item.segment
  };
};

export const convertConditions = (conditions, toUIFriendly) => {
  for (let i = 0; i < conditions.length; i++) {
    let conditionToProcessRecursively = conditions[i];

    if (toUIFriendly) {
      //convert a unomi notCondition into a more UI friendly "condition" with an isExcluded bit
      if (conditions[i].type === conditionTypes.NOT_CONDITION) {
        conditions[i] = conditions[i].parameterValues.subCondition;
        conditions[i].isExcluded = true;

        //if we are converting a notCondition into a booleanCondtion, we need to process the booleanCondition recursively
        if (conditions[i].type === conditionTypes.BOOLEAN_CONDITION) {
          conditionToProcessRecursively = conditions[i];
        }
      }

      // if the condition is boolean. checking subConditions for sourceid.
      // if sourceid is found, remove subaff2 from subConditions for display purposes.
      if (conditions[i].type === conditionTypes.BOOLEAN_CONDITION) {
        if (
          conditions[i].parameterValues.subConditions.some(
            con =>
              con.parameterValues.propertyName ===
              'properties.url.queryParams.sourceid'
          )
        ) {
          conditions[i] = getSourceIdDisplayCondition(conditions[i]);
        }
      }

      conditions[i]._id = uuidv4();
    } else {
      delete conditions[i]._id;

      const isExcluded = conditions[i].isExcluded;

      //remove this, regardless if it is true or false b/c unomi doesn't like unknown properties
      delete conditions[i].isExcluded;

      //convert a UI friendly condition that isExcluded into a properly formed unomi notCondition
      if (isExcluded) {
        conditions[i].parameterValues = {
          subCondition: cloneDeep(conditions[i])
        };
        conditions[i].type = conditionTypes.NOT_CONDITION;

        //if we converted a booleanCondtion into a notCondition, we still need to finish recursively processing its children
        if (
          conditions[i].parameterValues.subCondition.type ===
          conditionTypes.BOOLEAN_CONDITION
        ) {
          conditionToProcessRecursively =
            conditions[i].parameterValues.subCondition;
        }
      }

      // if sourceid found split condition to booleanCondition and add subaff2 and sourceid.
      if (
        conditions[i].parameterValues.propertyName ===
        'properties.url.queryParams.sourceid'
      ) {
        conditions[i] = buildSourceIdRuleCondition(
          conditions[i].parameterValues.propertyValues
        );
      }
    }

    if (
      conditionToProcessRecursively.type === conditionTypes.BOOLEAN_CONDITION &&
      Array.isArray(conditionToProcessRecursively.parameterValues.subConditions)
    ) {
      convertConditions(
        conditionToProcessRecursively.parameterValues.subConditions,
        toUIFriendly
      );
    }
  }
};

export const cloneConditions = (conditions = []) => {
  const newConditions = cloneDeep(conditions);
  for (let i = 0; i < newConditions.length; i++) {
    if (
      newConditions[i].type === conditionTypes.BOOLEAN_CONDITION &&
      Array.isArray(newConditions[i].parameterValues.subConditions)
    ) {
      newConditions[i].parameterValues.subConditions = cloneConditions(
        newConditions[i].parameterValues.subConditions
      );
    }
  }
  return newConditions;
};

export const findConditionInConditions = (conditions, conditionId) => {
  if (Array.isArray(conditions)) {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i]._id === conditionId) {
        return conditions[i];
      }
      if (
        conditions[i].type === conditionTypes.BOOLEAN_CONDITION &&
        Array.isArray(conditions[i].parameterValues.subConditions)
      ) {
        const matchingCondition = findConditionInConditions(
          conditions[i].parameterValues.subConditions,
          conditionId
        );
        if (matchingCondition) {
          return matchingCondition;
        }
      }
    }
  }
};

export const insertCondition = (
  conditions,
  condition,
  targetCondition,
  dropPosition
) => {
  if (Array.isArray(conditions)) {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i]._id === targetCondition._id) {
        if (
          dropPosition === dropPositions.INTO &&
          conditions[i].type === conditionTypes.BOOLEAN_CONDITION
        ) {
          conditions[i].parameterValues.subConditions.push(condition);
        } else {
          conditions.splice(
            dropPosition === dropPositions.AFTER ? i + 1 : i,
            0,
            condition
          );
        }

        return true;
      }
      if (
        conditions[i].type === conditionTypes.BOOLEAN_CONDITION &&
        Array.isArray(conditions[i].parameterValues.subConditions)
      ) {
        const hasInserted = insertCondition(
          conditions[i].parameterValues.subConditions,
          condition,
          targetCondition,
          dropPosition
        );
        if (hasInserted) {
          return true;
        }
      }
    }
  }
};

export const addCondition = (
  item,
  condition,
  targetCondition,
  dropPosition
) => (dispatch, getState) => {
  const newSegment = cloneDeep(item.segment);
  const newConditions = cloneConditions(
    newSegment.parameterValues.subConditions
  );

  if (targetCondition._id === newSegment._id) {
    newConditions.push(condition);
  } else {
    //look for the targetCondition recursively and insert at appropriate position
    insertCondition(newConditions, condition, targetCondition, dropPosition);
  }

  newSegment.parameterValues.subConditions = newConditions;

  dispatch(
    modifyAudience({
      id: item.id,
      newProperties: {
        segment: newSegment
      }
    })
  );
};

export const updateCondition = (item, condition, newProperties) => (
  dispatch,
  getState
) => {
  const newSegment = cloneDeep(item.segment);
  const newConditions = cloneConditions(
    newSegment.parameterValues.subConditions
  );

  let matchingCondition;
  if (condition._id === newSegment._id) {
    matchingCondition = newSegment;
  } else {
    matchingCondition = findConditionInConditions(newConditions, condition._id);
  }

  if (matchingCondition) {
    Object.assign(matchingCondition, newProperties);

    newSegment.parameterValues.subConditions = newConditions;

    dispatch(
      modifyAudience({
        id: item.id,
        newProperties: {
          segment: newSegment
        }
      })
    );
  }
};

export const removeConditionFromConditions = (conditions, condition) => {
  if (Array.isArray(conditions)) {
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i]._id === condition._id) {
        return conditions.splice(i, 1)[0];
      }
      if (
        conditions[i].type === conditionTypes.BOOLEAN_CONDITION &&
        Array.isArray(conditions[i].parameterValues.subConditions)
      ) {
        const removedCondition = removeConditionFromConditions(
          conditions[i].parameterValues.subConditions,
          condition
        );
        if (removedCondition) {
          return removedCondition;
        }
      }
    }
  }
};

export const removeCondition = (item, condition) => (dispatch, getState) => {
  const newSegment = cloneDeep(item.segment);
  const newConditions = cloneConditions(
    newSegment.parameterValues.subConditions
  );

  removeConditionFromConditions(newConditions, condition);

  newSegment.parameterValues.subConditions = newConditions;

  dispatch(
    modifyAudience({
      id: item.id,
      newProperties: {
        segment: newSegment
      }
    })
  );
};

export const getItem = getItemInternal(
  audienceModel,
  receiveAudience,
  setAudienceIsBusy,
  item => {
    if (item.segment) {
      //convert hte unomi segment into a more UI friendly value
      Object.assign(item, getUIValue(item));
    }
  }
);
export const saveItem = saveItemInternal(
  modifyAudience,
  item => {
    if (item.targetIndex === targetIndices.EVENT) {
      if (!item.pastEventType) {
        return 'Event Conditions require an Event Type to be selected in Targeting.';
      }
    }
  },
  itemCopy => {
    //convert back into a unomi segment
    itemCopy.segment = getQueryCondition(itemCopy);

    //delete temporary properties used to build pastEventCondition
    delete itemCopy.numberOfDays;
    delete itemCopy.minimumEventCount;
    delete itemCopy.maximumEventCount;
    delete itemCopy.pastEventType;
  }
);
