import { camelCase } from 'lodash';

import * as itemTypes from './items/item-types';
import navItems from './nav/nav-items';

// e.g. this converts 'datacampaign' to 'dataCampaignId',
// which is the key format that saved items uses
export const keyFromItemType = itemType =>
  `${camelCase(itemTypes.itemTypeNames[itemType])}Id`;

// convert our itemType adgroup -> Ad Group -> AD_GROUP
// to match the keys available in navItems
export const routeFromItemType = itemType =>
  camelCase(itemTypes.itemTypeNames[itemType])
    .replace(/([A-Z])/g, '_$1')
    .toUpperCase();

export const buildPathForitem = item => {
  // support both drafts and non-drafts
  // itemType has priority since some saved items have `type` available but its not what we are looking for
  const $itemType = item.itemType ?? item.type;

  const routeMeta = navItems[routeFromItemType($itemType)];

  if (routeMeta?.resolve) {
    const order = routeMeta.resolve;
    // This transforms our array of itemTypes (slugs) that are used for nesting, into a url
    // the adgroup route expands to: /campaign/:campaignid/adgroup/:adgroupid
    return order.reduce((path, type, idx) => {
      let idKey;
      if ($itemType === type) {
        idKey = 'id';
      } else {
        idKey = keyFromItemType(type);
      }

      path += `${type}/${item[idKey]}/`;

      // if this is the last segment of the order, add info to the url
      if (idx === order.length - 1) {
        path += 'info';
      }
      return path;
    }, '/');
  }

  return `/${$itemType}/${item.id}/info`;
};
