import { pickByKeyValue } from './utils';

export const findDirtyItems = obj => {
  let dirty = {};
  let objects = Object.values(obj);
  for (let item in objects) {
    let dirtyItem = pickByKeyValue(objects[item], 'isDirty', true);
    if (dirtyItem) dirty[dirtyItem.type] = dirtyItem;
  }
  return dirty;
};

// TODO: consider deferring this to window.unload
export const cacheDirtyStateSlices = store => next => action => {
  // Call other middleware before grabbing state
  next(action);

  let cachedDirtyState = {};
  let nextState = store.getState();
  let dirtyItems = findDirtyItems(nextState);
  let dirtySlices = Object.values(dirtyItems);

  if (dirtySlices.length > 0) {
    for (let slice in dirtySlices) {
      cachedDirtyState[dirtySlices[slice].type] =
        nextState[dirtySlices[slice].type];
    }
    localStorage.setItem('cachedDirtyState', JSON.stringify(cachedDirtyState));
  } else {
    localStorage.removeItem('cachedDirtyState');
  }
};
