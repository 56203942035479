import {
  modifyAudience,
  receiveAudience,
  setAudienceIsBusy,
  closeAudience
} from './audience.actions';
import itemReducer from '../items/item.reducers';

const audienceReducer = itemReducer(
  receiveAudience,
  setAudienceIsBusy,
  modifyAudience,
  closeAudience
);

export default audienceReducer;
