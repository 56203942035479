import React from 'react';
import { Box, Text, Button } from 'grommet';

function ErrorFallback({ error }) {
  return (
    <Box margin='large' pad='large'>
      <Text as='h1'>Oops...something went wrong:</Text>
      <pre>{error.message}</pre>
      <Button
        primary
        label='Try Again'
        onClick={() => window.location.reload()}
      ></Button>
    </Box>
  );
}

ErrorFallback.displayName = 'ErrorFallback';
export default ErrorFallback;
