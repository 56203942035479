import {
  modifyPage,
  receivePage,
  setPageIsBusy,
  closePage
} from './page.actions';
import itemReducer from '../items/item.reducers';

const pageReducer = itemReducer(
  receivePage,
  setPageIsBusy,
  modifyPage,
  closePage,
  (state, action) => {
    if (state.byId[action.payload.id].autoTransition === false) {
      delete state.byId[action.payload.id].autoTransition;
    }
  }
);

export default pageReducer;
