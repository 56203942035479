import {
  modifyFlow,
  receiveFlow,
  setFlowIsBusy,
  closeFlow
} from './flow.actions';

import itemReducer from '../items/item.reducers';

const flowReducer = itemReducer(
  receiveFlow,
  setFlowIsBusy,
  modifyFlow,
  closeFlow
);

export default flowReducer;
