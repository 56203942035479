import React, { useCallback, useRef, useState } from 'react';
import { Box, Button, Text, TextInput } from 'grommet';
import { FormClose } from 'grommet-icons';
import TagItem from './tag-item';

const TagSelector = ({
  tags = [],
  onTagAdd,
  onTagRemove,
  onBulkAdd, //optional prop if you want to support a comma delimited list of new tags
  bulkDelimiter = ',',
  onClear, //optional prop if you want to support clearing the entire list of tags
  disabled,
  suggestions,
  onTextChange = () => {},
  placeholder = 'Add Tag',
  width = 'auto',
  size = 'medium',
  canCreateNew = true,
  tagTextBuilder,
  canEdit = true
}) => {
  const [value, setValue] = useState('');
  const textRef = useRef();

  const [hasScroll, setHasScroll] = useState(false);

  const measuredRef = useCallback(
    node => {
      if (node !== null) {
        setHasScroll(node.scrollHeight > node.clientHeight);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tags]
  );

  return (
    <Box
      direction='row'
      border={{
        color: 'border400'
      }}
      round='xsmall'
      style={{ width, opacity: disabled ? 0.5 : 1 }}
      flex={{ shrink: 0, grow: 1 }}
    >
      <Box
        direction='row'
        wrap={true}
        style={{ maxWidth: '65%', maxHeight: '120px' }}
        overflow={{ vertical: 'auto' }}
        align='center'
        flex={{ shrink: 0 }}
        ref={measuredRef}
      >
        {tags?.map(t => (
          <TagItem
            size={size}
            key={t}
            tag={t}
            onRemoveClick={() => onTagRemove(t)}
            disabled={disabled}
            tagTextBuilder={tagTextBuilder}
            canEdit={canEdit}
          />
        ))}
      </Box>
      <Box flex={true} justify='between'>
        <TextInput
          name='tags'
          size={size}
          placeholder={placeholder}
          plain
          disabled={disabled || !canEdit}
          ref={textRef}
          value={value}
          onKeyUp={e => {
            if (
              canCreateNew &&
              e.key === 'Enter' &&
              e.target.value.trim().length
            ) {
              if (typeof onBulkAdd === 'function') {
                const bulkTags = e.target.value
                  .split(bulkDelimiter) //split on bulkDelimiter,
                  .map(bt => bt.trim()) //remove whitespace
                  .filter(bt => bt); //remove empty entries

                //filtering to non duplicate tags not already in the tag collection
                const bulkTagsToInsert = Array.from(new Set(bulkTags)).filter(
                  bt => tags.indexOf(bt) === -1
                );

                if (bulkTagsToInsert.length > 0) {
                  onBulkAdd(bulkTagsToInsert);
                }
              } else if (tags.indexOf(e.target.value) === -1) {
                const trimmedValue = e.target.value.trim();
                onTagAdd(trimmedValue);
              }
              setValue('');
              onTextChange('');
            }
          }}
          onChange={e => {
            setValue(e.target.value);
            onTextChange(e.target.value);
          }}
          suggestions={suggestions}
          onSelect={e => {
            onTagAdd(e.suggestion);
            setValue('');
            onTextChange('');
            textRef.current.focus();
          }}
        />
        {hasScroll && typeof onClear === 'function' && (
          <Box fill='horizontal' justify='end'>
            <Button color='#fff' onClick={onClear}>
              <Box
                direction='row'
                gap='small'
                align='center'
                background='dark-3'
                pad='xsmall'
                justify='center'
              >
                <Text>Clear All</Text>
                <FormClose />
              </Box>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

TagSelector.displayName = 'TagSelector';

export default TagSelector;
