import { v4 as uuidv4 } from 'uuid';
import {
  isApplicable,
  platforms
} from '../environment-config/environment-config';

export const dropPositions = {
  BEFORE: 'BEFORE',
  AFTER: 'AFTER',
  INTO: 'INTO'
};

export const conditionTypes = {
  BOOLEAN_CONDITION: 'booleanCondition',
  PROFILE_PROPERTY_CONDITION: 'profilePropertyCondition',
  PROFILE_SEGMENT_CONDITION: 'profileSegmentCondition',
  EVENT_PROPERTY_CONDITION: 'eventPropertyCondition',
  PAST_EVENT_CONDITION: 'pastEventCondition',
  PAST_EVENT_BOOLEAN_CONDITION: 'pastEventBooleanCondition',
  NOT_CONDITION: 'notCondition'
};

export const targetIndices = {
  PROFILE: 'profile',
  EVENT: 'event'
};

const EVENT_TYPES = [
  {
    label: 'Viewed Flow',
    value: 'flowView',
    condition: {
      type: 'booleanCondition',
      parameterValues: {
        operator: 'and',
        subConditions: [
          {
            type: 'eventTypeCondition',
            parameterValues: {
              eventTypeId: 'view'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'target.itemType',
              propertyValue: 'flow',
              comparisonOperator: 'equals'
            }
          }
        ]
      }
    }
  },
  {
    label: 'Viewed Campaign',
    value: 'offerView',
    condition: {
      type: 'booleanCondition',
      parameterValues: {
        operator: 'and',
        subConditions: [
          {
            type: 'eventTypeCondition',
            parameterValues: {
              eventTypeId: 'view'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'target.itemType',
              propertyValue: 'offer',
              comparisonOperator: 'equals'
            }
          }
        ]
      }
    }
  },
  {
    label: 'Clicked Campaign',
    value: 'offerClick',
    condition: {
      type: 'booleanCondition',
      parameterValues: {
        operator: 'and',
        subConditions: [
          {
            type: 'eventTypeCondition',
            parameterValues: {
              eventTypeId: 'click'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'target.itemType',
              propertyValue: 'offer',
              comparisonOperator: 'equals'
            }
          }
        ]
      }
    }
  },
  {
    label: 'Converted Campaign',
    value: 'offerConvert',
    condition: {
      type: 'booleanCondition',
      parameterValues: {
        operator: 'and',
        subConditions: [
          {
            type: 'eventTypeCondition',
            parameterValues: {
              eventTypeId: 'convert'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'target.itemType',
              propertyValue: 'offer',
              comparisonOperator: 'equals'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'properties.conversion',
              propertyValue: 'true',
              comparisonOperator: 'equals'
            }
          }
        ]
      }
    }
  },
  {
    label: 'Registered',
    value: 'register',
    condition: {
      type: 'booleanCondition',
      parameterValues: {
        operator: 'and',
        subConditions: [
          {
            type: 'eventTypeCondition',
            parameterValues: {
              eventTypeId: 'submit'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'target.itemType',
              propertyValue: 'form',
              comparisonOperator: 'equals'
            }
          },
          {
            type: 'eventPropertyCondition',
            parameterValues: {
              propertyName: 'properties.isFullReg',
              propertyValue: 'true',
              comparisonOperator: 'equals'
            }
          }
        ]
      }
    }
  },
  {
    label: 'Email Delivery Attempted',
    value: 'emailDeliveryAttempt',
    condition: {
      type: 'eventTypeCondition',
      parameterValues: {
        eventTypeId: 'emailOpen'
      }
    }
  },
  {
    label: 'Email Opened',
    value: 'emailOpen',
    condition: {
      type: 'eventTypeCondition',
      parameterValues: {
        eventTypeId: 'emailOpen'
      }
    }
  },
  {
    label: 'Email Clicked',
    value: 'emailClick',
    condition: {
      type: 'eventTypeCondition',
      parameterValues: {
        eventTypeId: 'emailClick'
      }
    }
  },
  {
    label: 'Email Unsubscribed',
    value: 'emailUnsubscribe',
    condition: {
      type: 'eventTypeCondition',
      parameterValues: {
        eventTypeId: 'emailUnsubscribe'
      }
    }
  },
  {
    label: 'Email Spam Complaint',
    value: 'emailSpamComplaint',
    condition: {
      type: 'eventTypeCondition',
      parameterValues: {
        eventTypeId: 'emailSpamComplaint'
      }
    }
  },
  {
    label: 'Email Bounced',
    value: 'emailBounce',
    condition: {
      type: 'eventTypeCondition',
      parameterValues: {
        eventTypeId: 'emailBounce'
      }
    }
  },
  {
    label: 'Email Bad Address',
    value: 'emailBadAddress',
    condition: {
      type: 'eventTypeCondition',
      parameterValues: {
        eventTypeId: 'emailBadAddress'
      }
    }
  }
];

export const PXP_EXCLUDED_EVENT_TYPES = [
  'emailDeliveryAttempt',
  'emailOpen',
  'emailClick',
  'emailUnsubscribe',
  'emailSpamComplaint',
  'emailBounce',
  'emailBadAddress'
];

export const APPLICABLE_EVENT_TYPES = isApplicable({ platform: platforms.CDP })
  ? [...EVENT_TYPES]
  : [...EVENT_TYPES.filter(et => !PXP_EXCLUDED_EVENT_TYPES.includes(et.value))];

export const booleanConditionModel = () => {
  return {
    parameterValues: {
      subConditions: [],
      operator: 'and'
    },
    type: conditionTypes.BOOLEAN_CONDITION,
    _id: uuidv4()
  };
};

export const pastEventBooleanConditionModel = () => {
  return {
    parameterValues: {
      subConditions: [],
      operator: 'and'
    },
    type: conditionTypes.PAST_EVENT_BOOLEAN_CONDITION,
    _id: uuidv4()
  };
};

export const profileConditionModel = () => {
  return {
    parameterValues: {
      propertyName: '',
      comparisonOperator: 'equals',
      propertyValue: ''
    },
    type: conditionTypes.PROFILE_PROPERTY_CONDITION,
    _id: uuidv4()
  };
};

export const eventConditionModel = () => {
  return {
    parameterValues: {
      propertyName: '',
      comparisonOperator: 'in',
      propertyValues: []
    },
    type: conditionTypes.EVENT_PROPERTY_CONDITION,
    _id: uuidv4()
  };
};

export const pastEventConditionModel = (
  numberOfDays,
  minimumEventCount,
  maximumEventCount,
  pastEventCondition
) => {
  const eventCondition = pastEventBooleanConditionModel();
  if (pastEventCondition) {
    eventCondition.parameterValues.subConditions.push(
      pastEventCondition.condition
    );
  }

  return {
    parameterValues: {
      numberOfDays,
      minimumEventCount,
      maximumEventCount,
      eventCondition
    },
    type: conditionTypes.PAST_EVENT_CONDITION,
    _id: uuidv4()
  };
};
