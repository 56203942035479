import {
  modifyUserAttribute,
  receiveUserAttribute,
  setUserAttributeIsBusy,
  closeUserAttribute
} from './user-attribute.actions';
import itemReducer from '../items/item.reducers';

const userAttributeReducer = itemReducer(
  receiveUserAttribute,
  setUserAttributeIsBusy,
  modifyUserAttribute,
  closeUserAttribute
);

export default userAttributeReducer;
