import {
  modifyDataDelivery,
  receiveDataDelivery,
  setDataDeliveryIsBusy,
  closeDataDelivery
} from './data-delivery.actions';
import itemReducer from '../../../items/item.reducers';

const dataDeliveryReducer = itemReducer(
  receiveDataDelivery,
  setDataDeliveryIsBusy,
  modifyDataDelivery,
  closeDataDelivery
);

export default dataDeliveryReducer;
