import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../items/item.actions';
import { authorizedFetch } from '../utils.js';

export const receiveBlock = createAction('receiveBlock');
export const setBlockIsBusy = createAction('setBlockIsBusy');
export const modifyBlock = createAction('modifyBlock');
export const closeBlock = createAction('closeBlock');

export const blockModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.CONTENT_BLOCK,
  contentBlockDefinition: {}
});

export const fetchContentBlock = id =>
  authorizedFetch(`/api/${itemTypes.CONTENT_BLOCK}/${id}`);

export const getItem = getItemInternal(
  blockModel,
  receiveBlock,
  setBlockIsBusy
);

export const saveItem = saveItemInternal(modifyBlock, item => {
  if (item.contentBlockDefinition && item.contentBlockDefinition.html) {
    const temp = document.createElement('div');
    temp.appendChild(
      document
        .createRange()
        .createContextualFragment(item.contentBlockDefinition.html)
    );

    if (temp.children.length > 1) {
      return 'Content Blocks must have a single root element';
    }
  }
});
