import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../items/item.actions';
import { authorizedFetch } from '../utils';

export const receiveReward = createAction('receiveReward');
export const setRewardIsBusy = createAction('setRewardIsBusy');
export const modifyReward = createAction('modifyReward');
export const closeReward = createAction('closeReward');

export const rewardModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.REWARD,
  flowIds: []
});

const serverState = {};

export const getItem = getItemInternal(
  rewardModel,
  payload => {
    serverState[payload.id] = payload;
    return receiveReward(payload);
  },
  setRewardIsBusy
);
export const saveItem = (() => {
  let thumbnailDataUrl;
  let backgroundImageDataUrl;

  return saveItemInternal(
    modifyReward,
    item => {
      if (!item.code) {
        return 'Code is required.';
      }
    },
    copy => {
      thumbnailDataUrl = copy.thumbnailDataUrl;
      backgroundImageDataUrl = copy.backgroundImageDataUrl;
      delete copy.thumbnailDataUrl;
      delete copy.backgroundImageDataUrl;
    },
    async (item, { id, version }, dispatch, modifyItemAction) => {
      let flowsToAdd = [],
        flowsToRemove = [];
      //compare item.flowIds with the originally GET response of flow ids and call remove/add flow/reward/rewardId
      if (!serverState[id]) {
        flowsToAdd = item.flowIds;
      } else {
        if (Array.isArray(serverState[id].flowIds)) {
          serverState[id].flowIds.forEach(f => {
            //flow was previously assigned to reward, but is not anymore, remove it
            if (Array.isArray(item.flowIds) && item.flowIds.indexOf(f) === -1) {
              flowsToRemove.push(f);
            }
          });

          if (Array.isArray(item.flowIds)) {
            item.flowIds.forEach(f => {
              //flow was not previously assigned to reward, and now it is. add it
              if (
                !Array.isArray(serverState[id].flowIds) ||
                serverState[id].flowIds.indexOf(f) === -1
              ) {
                flowsToAdd.push(f);
              }
            });
          }
        }
      }

      const allRequests = [];

      flowsToAdd.forEach(f => {
        allRequests.push(addRewardToFlow(f, id));
      });

      flowsToRemove.forEach(f => {
        allRequests.push(removeRewardFromFlow(f, id));
      });

      // /upload endpoint requires expected version for each call so have to perform these synchronously
      let expectedVersion = version;

      if (thumbnailDataUrl) {
        const thumbnailResponse = await uploadRewardImage(
          thumbnailDataUrl,
          'default',
          id,
          expectedVersion,
          dispatch,
          modifyItemAction
        );
        thumbnailDataUrl = null;
        expectedVersion = thumbnailResponse.version;
      }
      if (backgroundImageDataUrl) {
        await uploadRewardImage(
          backgroundImageDataUrl,
          'background',
          id,
          expectedVersion,
          dispatch,
          modifyItemAction
        );
        backgroundImageDataUrl = null;
      }

      serverState[id] = item;

      const results = await Promise.all(allRequests);
      return results;
    }
  );
})();

async function uploadRewardImage(
  url,
  type,
  rewardId,
  version,
  dispatch,
  modifyItemAction
) {
  const formData = new FormData();

  let imageData = await fetch(url).then(r => r.blob());
  formData.append('file', imageData);
  formData.append('type', type);
  formData.append('expectedVersion', version);

  try {
    const response = await authorizedFetch(
      `/api/${itemTypes.REWARD}/${rewardId}/upload`,
      'POST',
      formData,
      null,
      'multipart/form-data'
    );

    const keyName = type === 'default' ? 'thumbnail' : 'backgroundImage';
    const keyToClear =
      type === 'default' ? 'thumbnailDataUrl' : 'backgroundImageDataUrl';

    await dispatch(
      modifyItemAction({
        id: rewardId,
        newProperties: {
          [keyName]: response.data.url,
          [keyToClear]: null,
          version: response.version,
          id: response.id
        }
      })
    );
    return response;
  } catch (err) {
    return {
      isError: true,
      primary: `Unable to upload image.`,
      secondary: err.Message
    };
  }
}

const addRewardToFlow = (flowId, rewardId) => {
  return authorizedFetch(
    `/api/${itemTypes.FLOW}/${flowId}/reward/${rewardId}/add`,
    'POST'
  ).catch(err => {
    return {
      isError: true,
      primary: `Unable to add reward to flow ${flowId}.  `,
      secondary: err.Message
    };
  });
};

const removeRewardFromFlow = (flowId, rewardId) => {
  return authorizedFetch(
    `/api/${itemTypes.FLOW}/${flowId}/reward/${rewardId}/remove`,
    'POST'
  ).catch(err => {
    return {
      isError: true,
      primary: `Unable to remove reward from flow ${flowId}.  `,
      secondary: err.Message
    };
  });
};
