import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../items/item.actions';

export const receiveAudiencePriority = createAction('receiveAudiencePriority');
export const setAudiencePriorityIsBusy = createAction(
  'setAudiencePriorityIsBusy'
);
export const modifyAudiencePriority = createAction('modifyAudiencePriority');
export const closeAudiencePriority = createAction('closeAudiencePriority');

const audiencePriorityModel = id => ({
  id,
  audiencesOrder: [],
  flowIds: [],
  type: itemTypes.AUDIENCE_PRIORITY
});

export const getItem = getItemInternal(
  audiencePriorityModel,
  receiveAudiencePriority,
  setAudiencePriorityIsBusy
);
export const saveItem = saveItemInternal(modifyAudiencePriority, item => {
  if (item.flowIds.length === 0) {
    return 'Flows are required.';
  }
});
