import {
  modifyJobCampaign,
  receiveJobCampaign,
  setJobCampaignIsBusy,
  closeJobCampaign
} from './job-campaign.actions';
import itemReducer from '../../items/item.reducers';

const jobCampaignReducer = itemReducer(
  receiveJobCampaign,
  setJobCampaignIsBusy,
  modifyJobCampaign,
  closeJobCampaign
);

export default jobCampaignReducer;
