import React from 'react';
import { Box, Text } from 'grommet';
import { FormClose } from 'grommet-icons';

const TagItem = ({
  tag,
  onRemoveClick,
  maxWidth = '150px',
  disabled,
  size = 'medium',
  tagTextBuilder = t => t,
  tagTitleBuilder = tagTextBuilder,
  canEdit = true
}) => (
  <Box
    direction='row'
    align='center'
    style={{ maxWidth }}
    flex={{ shrink: 0 }}
    justify='between'
    background='light-4'
    round='xsmall'
    gap='xsmall'
    margin={{ left: 'xsmall', vertical: 'xxsmall' }}
    pad={{ vertical: 'xxsmall', horizontal: 'small' }}
    data-testid='tagItem'
  >
    <Text truncate title={tagTitleBuilder(tag)} size={size}>
      {tagTextBuilder(tag)}
    </Text>
    {!disabled && canEdit && (
      <Box onClick={onRemoveClick}>
        <FormClose size='small' data-testid='closeIcon' />
      </Box>
    )}
  </Box>
);

TagItem.displayName = 'TagItem';

export default TagItem;
