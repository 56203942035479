import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../items/item.actions';
import { getCapValidationMessage } from '../campaign-utils.js';
import { validateEpcOverride } from '../epc-override';

export const receiveCampaign = createAction('receiveCampaign');
export const setCampaignIsBusy = createAction('setCampaignIsBusy');
export const modifyCampaign = createAction('modifyCampaign');
export const closeCampaign = createAction('closeCampaign');

const campaignModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.CAMPAIGN,
  flowIds: [],
  capStrategy: 'none'
});

export const getItem = getItemInternal(
  campaignModel,
  receiveCampaign,
  setCampaignIsBusy
);
export const saveItem = saveItemInternal(modifyCampaign, item => {
  const overrideError = validateEpcOverride(item);
  if (overrideError) return overrideError;

  if (!item.advertiserId) {
    return 'Advertiser is required.';
  }

  if (!item.goal) {
    return 'Campaign Goal is required.';
  }

  return getCapValidationMessage({
    dailyCap: item.dailyConversionCap,
    monthlyCap: item.monthlyConversionCap,
    overallCap: item.overallConversionCap
  });
});
