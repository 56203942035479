import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../items/item.actions';

export const receiveAdvertiser = createAction('receiveAdvertiser');
export const setAdvertiserIsBusy = createAction('setAdvertiserIsBusy');
export const modifyAdvertiser = createAction('modifyAdvertiser');
export const closeAdvertiser = createAction('closeAdvertiser');

const advertiserModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.ADVERTISER
});

export const getItem = getItemInternal(
  advertiserModel,
  receiveAdvertiser,
  setAdvertiserIsBusy
);
export const saveItem = saveItemInternal(modifyAdvertiser, item => {
  if (!item.netsuiteId) {
    return 'Netsuite ID is required.';
  }
});
