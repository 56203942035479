import { createReducer } from '@reduxjs/toolkit';
import { updateName } from './home.actions';

const initialState = {
  name: 'ben'
};

const homeReducer = createReducer(initialState, {
  [updateName]: (state, action) => {
    state.name = action.payload;
  }
});

export default homeReducer;
