import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../../items/item.actions';
import { keyFromItemType } from '../../../routing-utils';

export const receiveEmbeddedFlowConfig = createAction(
  'receiveEmbeddedFlowConfig'
);
export const setEmbeddedFlowConfigIsBusy = createAction(
  'setEmbeddedFlowConfigIsBusy'
);
export const modifyEmbeddedFlowConfig = createAction(
  'modifyEmbeddedFlowConfig'
);
export const closeEmbeddedFlowConfig = createAction('closeEmbeddedFlowConfig');

export const EmbeddedFlowConfigModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.EMBEDDED_FLOW_CONFIG,
  [keyFromItemType(itemTypes.TRAFFIC_PARTNER)]: params.trafficpartnerid
});

export const getItem = getItemInternal(
  EmbeddedFlowConfigModel,
  receiveEmbeddedFlowConfig,
  setEmbeddedFlowConfigIsBusy
);
export const saveItem = saveItemInternal(modifyEmbeddedFlowConfig, item => {
  if (!item.trafficSourceId) {
    return 'Traffic Source is required.';
  }
  if (!item.flowId) {
    return 'Assigned Flow is required.';
  }
  if (!item.publishedFlowUrl) {
    return 'Assigned Flow Domain is required.';
  }
  if (!Array.isArray(item.requestingUrlContainsFilters)) {
    return 'Publisher Website Targeting is required.';
  }
});
