import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../items/item.actions';

export const receiveUserAttribute = createAction('receiveUserAttribute');
export const setUserAttributeIsBusy = createAction('setUserAttributeIsBusy');
export const modifyUserAttribute = createAction('modifyUserAttribute');
export const closeUserAttribute = createAction('closeUserAttribute');

export const userAttributeModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.USER_ATTRIBUTE
});

export const getItem = getItemInternal(
  userAttributeModel,
  receiveUserAttribute,
  setUserAttributeIsBusy
);
export const saveItem = saveItemInternal(modifyUserAttribute, item => {
  if (!item.code) {
    return 'Code is required.';
  }
});
