import { isItemNew } from '../utils';
import { itemTypeNames, buildApiPath } from '../items/item-types';
import { authorizedFetch } from '../utils.js';
import { addError, addSuccess } from '../toasts/toasts.actions';
import { cloneDeep } from 'lodash';
import * as itemTypes from '../items/item-types';

export const getItem = (
  model,
  receiveAction,
  setIsBusyAction,
  onReceiveItem
) => params => (dispatch, getState) => {
  const { id, type } = params;

  //item is already in reducer
  if (getState()[type].byId[id]) {
    return;
  }
  if (isItemNew(id)) {
    dispatch(receiveAction(model(id, `New ${itemTypeNames[type]}`, params)));
  } else {
    dispatch(setIsBusyAction({ id, isBusy: true }));

    authorizedFetch(buildApiPath(params))
      .then(response => {
        response.type = type;
        if (typeof onReceiveItem === 'function') {
          onReceiveItem(response);
        }
        dispatch(receiveAction(response));
        dispatch(setIsBusyAction({ id, isBusy: false }));
      })
      .catch(err => {
        dispatch(addError('Get Item failed', err.Message));
      });
  }
};

export const saveItem = (
  modifyItemAction,
  validate,
  onBeforeSave,
  onAfterSave
) => (params, item) => dispatch => {
  if (typeof validate === 'function') {
    const validationError = validate(item);
    if (validationError) {
      dispatch(addError('Validation Error', validationError));
      return;
    }
  }

  const copy = cloneDeep(item);
  if (typeof onBeforeSave === 'function') {
    onBeforeSave(copy);
  }

  //remove our UI properties from the object before we send to api
  copy.expectedVersion = item.version;
  delete copy.id;
  delete copy.type;
  delete copy.isDirty;
  delete copy.isBusy;
  delete copy.version;

  authorizedFetch(
    buildApiPath({
      type: item.type,
      ...params
    }),
    isItemNew(item.id) ? 'POST' : 'PUT',
    copy
  )
    .then(async response => {
      const newProperties = {
        id: response.id,
        version: response.version
      };

      if (item.type === itemTypes.RULE && response?.data) {
        newProperties.isPublishStale = response.data.isPublishStale;
      }

      if (item.type === itemTypes.TRAFFIC_PARTNER && response?.data) {
        newProperties.reportingKey = response.data;
      }

      dispatch(
        modifyItemAction({
          id: item.id,
          newProperties
        })
      );

      if (typeof onAfterSave === 'function') {
        const results = await onAfterSave(
          copy,
          {
            id: response.id,
            version: response.version
          },
          dispatch,
          modifyItemAction
        );
        if (Array.isArray(results)) {
          results
            .filter(r => r.isError)
            .forEach(err => {
              dispatch(addError(err.primary, err.secondary));
            });
        }
      }
      dispatch(addSuccess('Save successful'));
    })
    .catch(err => {
      dispatch(addError('Save failed', err.Message));
    });
};
