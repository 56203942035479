import {
  modifyAudiencePriority,
  receiveAudiencePriority,
  setAudiencePriorityIsBusy,
  closeAudiencePriority
} from './audience-priority.actions';
import itemReducer from '../items/item.reducers';

const audiencePriorityReducer = itemReducer(
  receiveAudiencePriority,
  setAudiencePriorityIsBusy,
  modifyAudiencePriority,
  closeAudiencePriority
);

export default audiencePriorityReducer;
