import {
  modifyReport,
  receiveReport,
  setReportIsBusy,
  closeReport
} from './report.actions';
import itemReducer from '../items/item.reducers';

const rewardReducer = itemReducer(
  receiveReport,
  setReportIsBusy,
  modifyReport,
  closeReport
);

export default rewardReducer;
