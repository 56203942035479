import React from 'react';
import { Box, Button, TextInput } from 'grommet';
import { Search as SearchIcon, FormClose } from 'grommet-icons';

const Search = ({
  value,
  onChange,
  suggestions,
  onSelect,
  style,
  ...delegated
}) => (
  <Box
    direction='row'
    alignSelf='center'
    align='center'
    pad={{ horizontal: 'small' }}
    border='all'
    flex={false}
    round={'small'}
    style={style}
    {...delegated}
  >
    <TextInput
      plain
      placeholder='Search'
      value={value}
      onChange={e => onChange(e.target.value)}
      suggestions={suggestions}
      onSelect={onSelect}
    />
    {value ? (
      <Button
        style={{ display: 'flex' }}
        plain
        onClick={() => {
          onChange('');
        }}
      >
        <FormClose />
      </Button>
    ) : (
      <SearchIcon />
    )}
  </Box>
);

Search.displayName = 'Search';

export default Search;
