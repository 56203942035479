import { createReducer } from '@reduxjs/toolkit';

export const RECEIVE_ITEM = (state, action) => {
  if (action.payload && action.payload.id) {
    state.byId[action.payload.id] = action.payload;
  }
};

export const CLOSE_ITEM = (state, action) => {
  if (action.payload && action.payload.id) {
    delete state.byId[action.payload.id];
  }
};

export const SET_IS_BUSY = (state, action) => {
  if (!state.byId[action.payload.id]) {
    //todo: move this somewhere
    state.byId[action.payload.id] = {
      id: action.payload.id
    };
  }
  state.byId[action.payload.id].isBusy = action.payload.isBusy;
};

const initialState = {
  byId: {}
};

const itemReducer = (
  receiveItemAction,
  setIsBusyAction,
  modifyItemAction,
  closeItemAction,
  onModifyAction
) =>
  createReducer(initialState, {
    [receiveItemAction]: RECEIVE_ITEM,
    [setIsBusyAction]: SET_IS_BUSY,
    [closeItemAction]: CLOSE_ITEM,
    [modifyItemAction]: (state, action) => {
      //after saving an item, going from a new-112312 id to an actual id
      if (action.payload.newProperties.id && action.payload.id) {
        state.byId[action.payload.id].isDirty = false;
        state.byId[action.payload.newProperties.id] =
          state.byId[action.payload.id];
        state.byId[action.payload.newProperties.id].id =
          action.payload.newProperties.id;
        Object.assign(
          state.byId[action.payload.newProperties.id],
          action.payload.newProperties
        );
      } else {
        Object.assign(
          state.byId[action.payload.id],
          action.payload.newProperties
        );
        state.byId[action.payload.id].isDirty = true;
        if (typeof onModifyAction === 'function') {
          onModifyAction(state, action);
        }
      }
    }
  });

export default itemReducer;
