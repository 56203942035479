import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../items/item.actions';

export const receiveTrafficPartner = createAction('receiveTrafficPartner');
export const setTrafficPartnerIsBusy = createAction('setTrafficPartnerIsBusy');
export const modifyTrafficPartner = createAction('modifyTrafficPartner');
export const closeTrafficPartner = createAction('closeTrafficPartner');

export const TrafficPartnerModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.TRAFFIC_PARTNER
});

export const getItem = getItemInternal(
  TrafficPartnerModel,
  receiveTrafficPartner,
  setTrafficPartnerIsBusy
);
export const saveItem = saveItemInternal(modifyTrafficPartner, item => {});
