import {
  modifyEmbeddedFlowConfig,
  receiveEmbeddedFlowConfig,
  setEmbeddedFlowConfigIsBusy,
  closeEmbeddedFlowConfig
} from './embedded-flow-config.actions';
import itemReducer from '../../../items/item.reducers';

const embeddedFlowConfigReducer = itemReducer(
  receiveEmbeddedFlowConfig,
  setEmbeddedFlowConfigIsBusy,
  modifyEmbeddedFlowConfig,
  closeEmbeddedFlowConfig
);

export default embeddedFlowConfigReducer;
