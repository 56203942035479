import { createReducer } from '@reduxjs/toolkit';
import { addToast, removeToast } from './toasts.actions';

const initialState = {
  items: []
};

const toastReducer = createReducer(initialState, {
  [addToast]: (state, action) => {
    state.items.push(action.payload);
  },
  [removeToast]: (state, action) => {
    state.items.splice(
      state.items.findIndex(item => item.id === action.payload),
      1
    );
  }
});

export default toastReducer;
