import { createAction } from '@reduxjs/toolkit';

import * as itemTypes from '../../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../../items/item.actions';
import { keyFromItemType } from '../../../routing-utils';
import { getCapValidationMessage } from '../../campaign-utils';

export const receiveDataDelivery = createAction('receiveDataDelivery');
export const setDataDeliveryIsBusy = createAction('setDataDeliveryIsBusy');
export const modifyDataDelivery = createAction('modifyDataDelivery');
export const closeDataDelivery = createAction('closeDataDelivery');

const dataDeliveryModel = (id, name, params) => {
  return {
    id,
    name,
    type: itemTypes.DATA_DELIVERY,
    startDate: new Date().toISOString(),
    [keyFromItemType(itemTypes.DATA_CAMPAIGN)]: params.campaignid
  };
};

export const getItem = getItemInternal(
  dataDeliveryModel,
  receiveDataDelivery,
  setDataDeliveryIsBusy
);

export const saveItem = saveItemInternal(modifyDataDelivery, item => {
  return getCapValidationMessage({
    dailyCap: item.dailyConversionCap,
    monthlyCap: item.monthlyConversionCap,
    overallCap: item.overallConversionCap
  });
});
