import { createAction } from '@reduxjs/toolkit';
import { cloneDeep, isEqual } from 'lodash';
import * as itemTypes from '../items/item-types';
import { v4 as uuidv4 } from 'uuid';
import { addError, addSuccess } from '../toasts/toasts.actions';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../items/item.actions';
import {
  authorizedFetch,
  convertKeyValueToObject,
  convertObjectToKeyValue
} from '../utils';
import {
  APPLICABLE_EVENT_TYPES,
  booleanConditionModel,
  ruleEventConditionModel,
  targetIndices
} from './rule.constants';
import {
  cloneConditions,
  convertConditions,
  findConditionInConditions,
  insertCondition,
  removeConditionFromConditions
} from '../audiences/audience.actions';

export const ruleActionsType = {
  sendFormAction: 'sendFormAction',
  sendProfileToMailingList: 'sendProfileToMailingList'
};

export const receiveRule = createAction('receiveRule');
export const setRuleIsBusy = createAction('setRuleIsBusy');
export const modifyRule = createAction('modifyRule');
export const closeRule = createAction('closeRule');

const ruleModel = (id, name) => ({
  id,
  name,
  type: itemTypes.RULE,
  targetIndex: targetIndices.EVENT,
  conditions: booleanConditionModel(),
  actions: [],
  actionItemRule: {}
});

const getQueryCondition = item => {
  const conditionCopy = cloneDeep(item.conditions);
  delete conditionCopy._id;

  const conditionsCopy = cloneConditions(
    conditionCopy.parameterValues.subConditions
  );
  convertConditions(conditionsCopy, false);
  conditionCopy.parameterValues.subConditions = conditionsCopy;

  //wrap the boolean condition in a ruleEventCondition
  if (item.targetIndex === targetIndices.EVENT) {
    const ruleEventCondition = ruleEventConditionModel(
      APPLICABLE_EVENT_TYPES.find(et => et.value === item.pastEventType)
    );
    delete ruleEventCondition._id;
    delete ruleEventCondition.parameterValues._id;

    if (conditionCopy.parameterValues.subConditions.length > 0) {
      ruleEventCondition.parameterValues.subConditions.push(conditionCopy);
    }

    return ruleEventCondition;
  }

  return conditionCopy;
};

const getUIValue = item => {
  let pastEventType;
  const ruleCondition = item.conditions;
  ruleCondition._id = uuidv4();

  if (item.targetIndex === targetIndices.EVENT) {
    let eventTypeCondition;
    if (ruleCondition.parameterValues?.eventCondition) {
      eventTypeCondition =
        ruleCondition.parameterValues?.eventCondition?.parameterValues
          .subConditions[0];
    } else {
      eventTypeCondition = ruleCondition.parameterValues?.subConditions[0];
    }

    if (eventTypeCondition) {
      pastEventType = APPLICABLE_EVENT_TYPES.find(et =>
        isEqual(et.condition, eventTypeCondition)
      );
    }

    //additionalFilters are always the second subCondition, if there were any defined
    let booleanCondition;
    if (ruleCondition.parameterValues?.eventCondition) {
      booleanCondition =
        ruleCondition.parameterValues?.eventCondition?.parameterValues
          .subConditions[1];
    } else {
      booleanCondition = ruleCondition.parameterValues?.subConditions[1];
    }

    if (!booleanCondition) {
      booleanCondition = booleanConditionModel();
    }
    booleanCondition._id = uuidv4();
    convertConditions(booleanCondition.parameterValues.subConditions, true);

    return {
      conditions: booleanCondition,
      pastEventType: pastEventType?.value
    };
  }

  convertConditions(ruleCondition.parameterValues.subConditions, true);

  return {
    conditions: ruleCondition
  };
};

export const addCondition = (
  item,
  condition,
  targetCondition,
  dropPosition
) => (dispatch, getState) => {
  const newCondition = cloneDeep(item.conditions);
  const newConditions = cloneConditions(
    newCondition.parameterValues.subConditions
  );

  if (targetCondition._id === newCondition._id) {
    newConditions.push(condition);
  } else {
    //look for the targetCondition recursively and insert at appropriate position
    insertCondition(newConditions, condition, targetCondition, dropPosition);
  }

  newCondition.parameterValues.subConditions = newConditions;

  dispatch(
    modifyRule({
      id: item.id,
      newProperties: {
        conditions: newCondition
      }
    })
  );
};

export const updateCondition = (item, condition, newProperties) => (
  dispatch,
  getState
) => {
  const newCondition = cloneDeep(item.conditions);
  const newConditions = cloneConditions(
    newCondition.parameterValues.subConditions
  );

  let matchingCondition;
  if (condition._id === newCondition._id) {
    matchingCondition = newCondition;
  } else {
    matchingCondition = findConditionInConditions(newConditions, condition._id);
  }

  if (matchingCondition) {
    Object.assign(matchingCondition, newProperties);

    newCondition.parameterValues.subConditions = newConditions;

    dispatch(
      modifyRule({
        id: item.id,
        newProperties: {
          conditions: newCondition
        }
      })
    );
  }
};

export const removeCondition = (item, condition) => (dispatch, getState) => {
  const newCondition = cloneDeep(item.conditions);
  const newConditions = cloneConditions(
    newCondition.parameterValues.subConditions
  );

  removeConditionFromConditions(newConditions, condition);

  newCondition.parameterValues.subConditions = newConditions;

  dispatch(
    modifyRule({
      id: item.id,
      newProperties: {
        conditions: newCondition
      }
    })
  );
};

// Publish Rule
export const publishRule = (id, modifyItem) => async (dispatch, getState) => {
  try {
    const res = await authorizedFetch(`/api/rule/${id}/publish`, 'POST');
    dispatch(addSuccess('Publish successful'));
    modifyItem({
      id,
      isPublishStale: false,
      version: res.version
    });
  } catch (e) {
    dispatch(addError('Publish failed', e.Message));
  }
};

// Activate Rule
export const activateRule = (id, modifyItem) => async (dispatch, getState) => {
  try {
    const res = await authorizedFetch(`/api/rule/${id}/activate`, 'POST');
    dispatch(addSuccess('Rule activated successfully'));
    modifyItem({
      id,
      activated: true,
      version: res.version
    });
  } catch (e) {
    dispatch(addError('Rule activation Failed', e.Message));
  }
};

// Deactivate Rule
export const deactivateRule = (id, modifyItem) => async (
  dispatch,
  getState
) => {
  try {
    const res = await authorizedFetch(`/api/rule/${id}/deactivate`, 'POST');
    dispatch(addSuccess('Rule deactivated successfully'));
    modifyItem({
      id,
      activated: false,
      version: res.version
    });
  } catch (e) {
    dispatch(addError('Rule deactivation Failed', e.Message));
  }
};

// converts Unomi payload to UI friendly value
export const unomiActionToUI = ({ actions }) => {
  if (Array.isArray(actions) && actions.length) {
    return actions.map(action => {
      switch (action.body.type) {
        case ruleActionsType.sendFormAction:
          return {
            id: action.id,
            name: action.name,
            description: action.description,
            dateModified: action.dateModified,
            type: action.body.type,
            method: action.body.parameterValues.method,
            url: action.body.parameterValues.url,
            headers: action.body.parameterValues.headers
              ? convertObjectToKeyValue(action.body.parameterValues.headers)
              : [],
            parameters: JSON.stringify(
              action.body.parameterValues.parameters,
              null,
              2
            )
          };
        case ruleActionsType.sendProfileToMailingList:
          return {
            id: action.id,
            name: action.name,
            description: action.description,
            dateModified: action.dateModified,
            type: action.body.type,
            mailingListId: action.body.parameterValues.mailingListId
          };
        default:
          return action;
      }
    });
  }
  return actions;
};

// converts UI payload to unomi friendly value
const uiToUnomiAction = actions => {
  if (Array.isArray(actions) && actions.length) {
    return actions.map(action => {
      switch (action.type) {
        case ruleActionsType.sendFormAction:
          return {
            id: action.id,
            name: action.name,
            description: action.description,
            body: {
              type: action.type,
              parameterValues: {
                method: action.method,
                body: 'JSON',
                url: action.url,
                headers: action.headers
                  ? convertKeyValueToObject(action.headers)
                  : {},
                parameters: action.parameters
                  ? JSON.parse(action.parameters)
                  : {}
              }
            }
          };
        case ruleActionsType.sendProfileToMailingList:
          return {
            id: action.id,
            name: action.name,
            description: action.description,
            body: {
              type: action.type,
              parameterValues: {
                mailingListId: action.mailingListId
              }
            }
          };
        default:
          return action;
      }
    });
  }
  return actions;
};

export const getItem = getItemInternal(
  ruleModel,
  receiveRule,
  setRuleIsBusy,
  item => {
    if (item.conditions) {
      //convert the unomi condition into a more UI friendly value
      Object.assign(
        item,
        getUIValue({ ...item, targetIndex: targetIndices.EVENT })
      );
    } else {
      Object.assign(item, {
        conditions: booleanConditionModel(),
        targetIndex: targetIndices.EVENT
      });
    }
    if (!item.actionItemRule) {
      Object.assign(item, { actionItemRule: {} });
    }
  }
);

const actionsPayload = itemCopy => {
  let items;

  if (Object.keys(itemCopy.actionItemRule).length !== 0) {
    items = [...itemCopy.actions, itemCopy.actionItemRule];
  }

  //convert back into a unomi action
  const unomiActions = uiToUnomiAction(items);

  // Remove duplicates
  return unomiActions
    ? [...new Map(unomiActions.map(v => [v.id, v])).values()]
    : itemCopy.actions;
};

export const saveItem = saveItemInternal(
  modifyRule,
  item => {},
  itemCopy => {
    //convert back into a unomi condition
    itemCopy.conditions = getQueryCondition({
      ...itemCopy,
      targetIndex: targetIndices.EVENT
    });
    itemCopy.actions = actionsPayload(itemCopy);
    delete itemCopy.actionItemRule;
    localStorage.removeItem('actionType');
  }
);
