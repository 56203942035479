import {
  modifyTrafficSource,
  receiveTrafficSource,
  setTrafficSourceIsBusy,
  closeTrafficSource
} from './traffic-source.actions';
import itemReducer from '../../items/item.reducers';

const trafficSourceReducer = itemReducer(
  receiveTrafficSource,
  setTrafficSourceIsBusy,
  modifyTrafficSource,
  closeTrafficSource
);

export default trafficSourceReducer;
