import {
  modifyRule,
  receiveRule,
  setRuleIsBusy,
  closeRule
} from './rule.actions';
import itemReducer from '../items/item.reducers';

const ruleReducer = itemReducer(
  receiveRule,
  setRuleIsBusy,
  modifyRule,
  closeRule
);

export default ruleReducer;
