import {
  modifyCallCampaign,
  receiveCallCampaign,
  setCallCampaignIsBusy,
  closeCallCampaign
} from './call-campaign.actions';
import itemReducer from '../../items/item.reducers';

const callCampaignReducer = itemReducer(
  receiveCallCampaign,
  setCallCampaignIsBusy,
  modifyCallCampaign,
  closeCallCampaign
);

export default callCampaignReducer;
