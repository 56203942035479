import { get } from 'lodash';
import { DateTime } from 'luxon';
import { authorizedFetch } from './utils';

const USER_ZONE = DateTime.now().zoneName;

//This will take a local date, convert it to NYC time zone
//  ie: I choose 1/28/2022 as a date in Central time (midnight on this day)
//     which is actually '2022-01-28T06:00:00.000Z' in utc time
//  and converts the date the appropriate offset to be midnight in NYC time
//     which is actually '2022-01-28T05:00:00.000Z' in utc time  (there is one hour difference between the two)
export const toNYCTimeStamp = sourceDate => {
  return DateTime.fromJSDate(sourceDate)
    .setZone('America/New_York', {
      keepLocalTime: true
    })
    .toUTC()
    .toISO();
};

//This will take a utc date, parse it as if it was in the local timezone
// convert it to NYC timezone
// then get the underlying date entity
//  (essentially the opposite of the toNYCTimeStamp above)
export const toLocalTimeStamp = sourceDate => {
  return DateTime.fromISO(sourceDate, { zone: 'America/New_York' })
    .setZone(USER_ZONE, {
      keepLocalTime: true
    })
    .toUTC()
    .toISO();
};

//takes utc date and outputs actual yyyy-mm-dd string for nyc
export const toNYCDate = sourceDate => {
  return DateTime.fromISO(sourceDate, {
    zone: 'America/New_York'
  }).toFormat('yyyy-MM-dd');
};

export const getComparisonDates = range => {
  const fromDatejs = DateTime.fromISO(range[0], { zone: 'America/New_York' });
  const toDatejs = DateTime.fromISO(range[1], { zone: 'America/New_York' });

  const msDiff = toDatejs.diff(fromDatejs, 'millisecond');

  const comparisonFromDate = fromDatejs.minus(msDiff, 'millisecond');
  const comparisonToDate = toDatejs.minus(msDiff, 'millisecond');

  return { comparisonFromDate, comparisonToDate };
};

export const recencyFilters = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  LAST_HOUR: 'Last Hour',
  LAST_12_HOURS: 'Last 12 Hours',
  LAST_24_HOURS: 'Last 24 Hours',
  LAST_7_DAYS: 'Last 7 Days',
  LAST_10_DAYS: 'Last 10 Days',
  LAST_30_DAYS: 'Last 30 Days',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last Month',
  CUSTOM: 'Custom'
};

export const recencyFilterOptions = Object.values(recencyFilters);

export const elasticMinRangeFromRecencyFilter = {
  [recencyFilters.TODAY]: 'now/d',
  [recencyFilters.YESTERDAY]: 'now-1d/d',
  [recencyFilters.LAST_HOUR]: 'now-1h',
  [recencyFilters.LAST_12_HOURS]: 'now-12h',
  [recencyFilters.LAST_24_HOURS]: 'now-24h',
  [recencyFilters.LAST_7_DAYS]: 'now-7d/d',
  [recencyFilters.LAST_10_DAYS]: 'now-10d/d',
  [recencyFilters.LAST_30_DAYS]: 'now-30d/d',
  [recencyFilters.LAST_MONTH]: 'now-1M/M',
  [recencyFilters.THIS_MONTH]: 'now/M'
};

export const elasticMaxRangeFromRecencyFilter = {
  [recencyFilters.YESTERDAY]: { lt: 'now/d' },
  [recencyFilters.LAST_7_DAYS]: { lt: 'now/d' },
  [recencyFilters.LAST_10_DAYS]: { lt: 'now/d' },
  [recencyFilters.LAST_30_DAYS]: { lt: 'now/d' },
  [recencyFilters.LAST_MONTH]: { lt: 'now/M' },
  [recencyFilters.THIS_MONTH]: { lt: 'now' }
};

export const elasticIntervalFromRecencyFilter = {
  [recencyFilters.TODAY]: '1h',
  [recencyFilters.YESTERDAY]: '1h',
  [recencyFilters.LAST_HOUR]: '5m',
  [recencyFilters.LAST_12_HOURS]: '1h',
  [recencyFilters.LAST_24_HOURS]: '1h',
  [recencyFilters.LAST_7_DAYS]: '1d',
  [recencyFilters.LAST_10_DAYS]: '1d',
  [recencyFilters.LAST_30_DAYS]: '1d',
  [recencyFilters.LAST_MONTH]: '1d',
  [recencyFilters.THIS_MONTH]: '1d'
};

export const canGroupHourly = (recencyFilter, customDatePeriod) => {
  // date range is less than one day
  if (
    recencyFilter === recencyFilters.LAST_12_HOURS ||
    recencyFilter === recencyFilters.LAST_HOUR ||
    recencyFilter === recencyFilters.TODAY
  ) {
    return true;
    // date range is >= 3 days
  } else if (customDatePeriod) {
    const distance = getDaysBetween(customDatePeriod[0], customDatePeriod[1]);
    if (distance <= 3) {
      return true;
    }
  }
  return false;
};

export const getInterval = (recencyFilter, hourly = false) => {
  if (recencyFilter === recencyFilters.LAST_HOUR && hourly) {
    return '5m';
  } else if (hourly) {
    return '1h';
  } else {
    return '1d';
  }
};

export const nivoPrecisionFromRecencyFilter = {
  [recencyFilters.TODAY]: 'hour',
  [recencyFilters.YESTERDAY]: 'hour',
  [recencyFilters.LAST_HOUR]: 'minute',
  [recencyFilters.LAST_12_HOURS]: 'hour',
  [recencyFilters.LAST_24_HOURS]: 'hour',
  [recencyFilters.LAST_7_DAYS]: 'day',
  [recencyFilters.LAST_10_DAYS]: 'day',
  [recencyFilters.LAST_30_DAYS]: 'day',
  [recencyFilters.LAST_MONTH]: 'day',
  [recencyFilters.THIS_MONTH]: 'day'
};

export const nivoFormatFromRecencyFilter = {
  [recencyFilters.TODAY]: '%I:%M %p',
  [recencyFilters.YESTERDAY]: '%I:%M %p',
  [recencyFilters.LAST_HOUR]: '%I:%M %p',
  [recencyFilters.LAST_12_HOURS]: '%I:%M %p',
  [recencyFilters.LAST_24_HOURS]: '%I:%M %p',
  [recencyFilters.LAST_7_DAYS]: '%b %d',
  [recencyFilters.LAST_10_DAYS]: '%b %d',
  [recencyFilters.LAST_30_DAYS]: '%b %d',
  [recencyFilters.LAST_MONTH]: '%b %d',
  [recencyFilters.THIS_MONTH]: '%b %d'
};

export const nivoTickValuesFromRecencyFilter = {
  [recencyFilters.TODAY]: 'every 1 hour',
  [recencyFilters.YESTERDAY]: 'every 1 hour',
  [recencyFilters.LAST_HOUR]: 'every 5 minutes',
  [recencyFilters.LAST_12_HOURS]: 'every 1 hour',
  [recencyFilters.LAST_24_HOURS]: 'every 2 hours',
  [recencyFilters.LAST_7_DAYS]: 'every 1 day',
  [recencyFilters.LAST_10_DAYS]: 'every 1 day',
  [recencyFilters.LAST_30_DAYS]: 'every 3 days',
  [recencyFilters.LAST_MONTH]: 'every 3 days',
  [recencyFilters.THIS_MONTH]: 'every 3 days'
};

export const nivoAxisLabelFromRecencyFilter = {
  [recencyFilters.TODAY]: 'Time',
  [recencyFilters.YESTERDAY]: 'Time',
  [recencyFilters.LAST_HOUR]: 'Time',
  [recencyFilters.LAST_12_HOURS]: 'Time',
  [recencyFilters.LAST_24_HOURS]: 'Time',
  [recencyFilters.LAST_7_DAYS]: 'Date',
  [recencyFilters.LAST_10_DAYS]: 'Date',
  [recencyFilters.LAST_30_DAYS]: 'Date',
  [recencyFilters.LAST_MONTH]: 'Date',
  [recencyFilters.THIS_MONTH]: 'Date'
};

export const getElasticTimeZoneOffset = () => 'America/New_York';

export const getDatePeriodFromRecencyFilter = recencyFilter => {
  if (recencyFilter !== recencyFilters.CUSTOM) {
    let dates;
    switch (recencyFilter) {
      case recencyFilters.LAST_HOUR: {
        let to = new Date();
        let from = new Date(to);
        from.setHours(from.getHours() - 1);
        dates = [from, to];
        break;
      }
      case recencyFilters.LAST_12_HOURS: {
        let to = new Date();
        let from = new Date(to);
        from.setHours(from.getHours() - 12);
        dates = [from, to];
        break;
      }
      case recencyFilters.LAST_24_HOURS: {
        let to = new Date();
        let from = new Date(to);
        from.setHours(from.getHours() - 24);
        dates = [from, to];
        break;
      }
      case recencyFilters.LAST_WEEK: {
        let to = new Date();
        let from = new Date(to);
        from.setDate(from.getDate() - 7);
        dates = [from, to];
        break;
      }
      case recencyFilters.THIS_MONTH: {
        let startOfMonth = DateTime.now()
          .setZone('America/New_York')
          .startOf('month')
          .toJSDate();
        let today = DateTime.now()
          .setZone('America/New_York')
          .toJSDate();

        dates = [startOfMonth, today];
        break;
      }
      case recencyFilters.LAST_MONTH: {
        let start = DateTime.now()
          .setZone('America/New_York')
          .minus({ months: 1 })
          .startOf('month')
          .toJSDate();
        let end = DateTime.now()
          .setZone('America/New_York')
          .minus({ months: 1 })
          .endOf('month')
          .toJSDate();

        dates = [start, end];
        break;
      }
      case recencyFilters.LAST_7_DAYS: {
        // Excluding present day
        let yesterday = DateTime.now()
          .setZone('America/New_York')
          .minus({ days: 1 })
          .endOf('day')
          .toJSDate();
        let sevenDaysAgo = DateTime.now()
          .setZone('America/New_York')
          .minus({ days: 7 })
          .startOf('day')
          .toJSDate();

        dates = [sevenDaysAgo, yesterday];
        break;
      }
      case recencyFilters.LAST_10_DAYS: {
        let yesterday = DateTime.now()
          .setZone('America/New_York')
          .minus({ days: 1 })
          .endOf('day')
          .toJSDate();
        let tenDaysAgo = DateTime.now()
          .setZone('America/New_York')
          .minus({ days: 10 })
          .startOf('day')
          .toJSDate();

        dates = [tenDaysAgo, yesterday];
        break;
      }
      case recencyFilters.LAST_30_DAYS: {
        let yesterdayMinus30 = DateTime.now()
          .setZone('America/New_York')
          .minus({ days: 30 })
          .startOf('day')
          .toJSDate();
        let yesterday = DateTime.now()
          .setZone('America/New_York')
          .minus({ days: 1 })
          .endOf('day')
          .toJSDate();

        dates = [yesterdayMinus30, yesterday];
        break;
      }
      case recencyFilters.TODAY: {
        let from = DateTime.now()
          .setZone('America/New_York')
          .startOf('day')
          .toJSDate();
        let to = DateTime.fromJSDate(from)
          .setZone('America/New_York')
          .endOf('day')
          .toJSDate();

        dates = [from, to];
        break;
      }
      case recencyFilters.YESTERDAY: {
        let from = DateTime.now()
          .setZone('America/New_York')
          .minus({ days: 1 })
          .startOf('day')
          .toJSDate();
        let to = DateTime.fromJSDate(from)
          .setZone('America/New_York')
          .endOf('day')
          .toJSDate();

        dates = [from, to];
        break;
      }
      default:
      //noop;
    }
    return [dates[0].toISOString(), dates[1].toISOString()];
  }
};

/*
  Extended bounds: min: 2021-09-03
                   max: 2021-09-03T23:99:99:999    (no Z, clamped to max hours of day)

  Date Range       gte: 2021-09-03
                   lte: 2021-09-03T23:99:99:999    (no Z, clamped to max hours of day)
*/

export const getRange = customDatePeriod => {
  return {
    gte: customDatePeriod[0],
    lte: customDatePeriod[1]
  };
};

export const getComparisonRange = customDatePeriod => {
  const { comparisonFromDate, comparisonToDate } = getComparisonDates(
    customDatePeriod
  );
  return {
    gte: comparisonFromDate
      .startOf('day')
      .toJSDate()
      .toISOString(),
    lt: comparisonToDate
      .startOf('day')
      .toJSDate()
      .toISOString()
  };
};

export const getBounds = customDatePeriod => {
  return {
    min: customDatePeriod[0],
    max: customDatePeriod[1]
  };
};

export const getComparisonBounds = customDatePeriod => {
  const { comparisonFromDate, comparisonToDate } = getComparisonDates(
    customDatePeriod
  );
  return {
    min: comparisonFromDate
      .startOf('day')
      .toJSDate()
      .toISOString(),
    max: comparisonToDate
      .endOf('day')
      .toJSDate()
      .toISOString()
  };
};

export const getDaysBetween = (start, end) => {
  return Math.ceil((new Date(end) - new Date(start)) / (1000 * 3600 * 24));
};

export const downloadCsv = (filename, stringContents) => {
  let csvContent = `data:text/csv;charset=UTF-8,${encodeURIComponent(
    stringContents
  )}`;
  var link = document.createElement('a');
  link.setAttribute('href', csvContent);
  link.setAttribute('download', `${filename}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
  }, 10000);
};

export const elasticComparisonToRange = comparison => {
  const regex = /([0-9]{1,2})([a-zA-Z]{1})/g;
  return Object.values(comparison).map(w => {
    // We have a chance of a date string being returned from getComparisonRange
    const now = new Date(); // now
    const existingDate = new Date(w);
    if (!isNaN(existingDate)) {
      return existingDate;
    } else if (w.includes('now/d') || w.includes('now/')) {
      return now;
    }
    // if we reach this point, we are dealing with comparison bounds
    // matchAll so we have access to the entire group
    const [match] = w.matchAll(regex);
    // we can expect a single match (at most) from the comparison
    // so we destructure the elements we need. [full match, group 1, group 2]
    const [, duration, period] = match;
    // now <- period - duration
    switch (period) {
      case 'h':
        now.setHours(now.getHours() - duration);
        break;
      case 'd':
        now.setDate(now.getDate() - duration);
        break;
      case 'M':
        now.setMonth(now.getMonth() - duration);
        break;
      case 'w':
        now.setDate(now.getDate() - duration * 7);
        break;
      default:
        throw new Error('[comparisonToRange] Unknown comparison period');
    }
    return now;
  });
};

export const createContextEventUrl = ({
  range,
  ignore = true,
  recency,
  indices
} = {}) => {
  let url = '/api/cds-search/';
  if (indices) {
    url += `${indices.join(',')}/_search`;
  } else if (recency === recencyFilters.CUSTOM) {
    url += `${getIndicesInRange(range)}/_search`;
  } else if (recency) {
    url += `${getIndicesInRange(
      getDatePeriodFromRecencyFilter(recency)
    )}/_search`;
  } else if (!recency && range) {
    url += `${getIndicesInRange(range)}/_search`;
  } else {
    url += `context-event-date-*/_search`;
  }

  if (ignore) url += '?ignore_unavailable=true';

  return url;
};

export const getIndicesInRange = range => {
  const indiciesToUse = [];
  const start = new Date(range[0]);
  const end = new Date(range[1]);

  //need to use UTCMonth here because the event indices are bucketed via UTC time
  if (start.getUTCMonth() === end.getUTCMonth()) {
    const month = zeroPad(end.getUTCMonth() + 1);
    indiciesToUse.push(`context-event-date-${end.getUTCFullYear()}-${month}`);
  } else if (end.getUTCMonth() - start.getUTCMonth() === 1) {
    const startMonth = zeroPad(start.getUTCMonth() + 1);
    const endMonth = zeroPad(end.getUTCMonth() + 1);
    indiciesToUse.push(
      `context-event-date-${end.getUTCFullYear()}-${startMonth}`
    );
    indiciesToUse.push(
      `context-event-date-${end.getUTCFullYear()}-${endMonth}`
    );
  } else {
    start.setUTCDate(1);
    end.setUTCDate(2);
    while (start <= end) {
      const month = zeroPad(start.getUTCMonth() + 1);
      indiciesToUse.push(
        `context-event-date-${start.getUTCFullYear()}-${month}`
      );
      start.setUTCMonth(start.getUTCMonth() + 1);
    }
  }
  return indiciesToUse.join(',');
};

export const zeroPad = month => {
  if (month < 10) {
    return '0' + month;
  }
  return month;
};

export const getLastXIndices = numberToGet => {
  const indiciesToUse = [];
  const now = new Date();

  for (let i = 0; i < numberToGet; i++) {
    let month = zeroPad(now.getUTCMonth() + 1);

    indiciesToUse.push(`context-event-date-${now.getUTCFullYear()}-${month}`);

    now.setUTCMonth(now.getUTCMonth() - 1);
  }

  return indiciesToUse;
};

export const getMapping = (
  setAvailableFields,
  includeVars = true,
  includeUserAttrs = true
) => {
  const indiciesToUse = getLastXIndices(2);

  authorizedFetch(
    `/api/cds-search/${indiciesToUse.join(
      ','
    )}/_mapping?ignore_unavailable=true`,
    'GET'
  )
    .then(response => {
      let availableFilters = new Set();
      Object.keys(response).forEach(indexKey => {
        //add unique queryparams
        if (
          get(
            response[indexKey],
            'mappings.properties.properties.properties.url.properties.queryParams.properties'
          )
        ) {
          Object.keys(
            response[indexKey].mappings.properties.properties.properties.url
              .properties.queryParams.properties
          ).forEach(propertyKey => {
            availableFilters.add(`properties.url.queryParams.${propertyKey}`);
          });
        }
        //add unique vars
        if (includeVars) {
          if (
            get(
              response[indexKey],
              'mappings.properties.properties.properties.vars.properties'
            )
          ) {
            Object.keys(
              response[indexKey].mappings.properties.properties.properties.vars
                .properties
            ).forEach(propertyKey => {
              availableFilters.add(`properties.vars.${propertyKey}`);
            });
          }
        }

        //add unique userAttrs
        if (includeUserAttrs) {
          if (
            get(
              response[indexKey],
              'mappings.properties.properties.properties.userAttrs.properties'
            )
          ) {
            Object.keys(
              response[indexKey].mappings.properties.properties.properties
                .userAttrs.properties
            ).forEach(propertyKey => {
              availableFilters.add(`properties.userAttrs.${propertyKey}`);
            });
          }
        }
      });

      setAvailableFields(Array.from(availableFilters));
    })
    .catch(e => {
      console.log(e);
    });
};

export const getFormattedDate = (timestamp, recencyFilter) => {
  switch (recencyFilter) {
    case 'HOURLY':
      return (
        timestamp &&
        new Date(timestamp).toLocaleTimeString('en-US', {
          timeZone: 'America/New_York'
        })
      );

    case recencyFilters.LAST_HOUR:
    case recencyFilters.LAST_12_HOURS:
    case recencyFilters.LAST_24_HOURS:
    case recencyFilters.TODAY:
    case recencyFilters.YESTERDAY:
    case recencyFilters.LAST_WEEK:
    case recencyFilters.LAST_10_DAYS:
    case recencyFilters.LAST_MONTH:
    default:
      return timestamp && timestamp.split('T')[0];
  }
};

export const KEYWORD_ONLY_FIELDS = [
  'properties.advertiserId',
  'properties.campaignId',
  'properties.adGroupId',
  'properties.adCreativeId',
  'properties.jobCampaignData.jobs.company'
];

export const NESTED_FIELDS = [
  {
    field: 'properties.jobCampaignData.jobs.company',
    path: 'properties.jobCampaignData.jobs'
  }
];

//recursively iterates through the nested mapping to build up a flattened full path mapping
const processEventProperties = (parentPath, properties) => {
  let propertyMap = {};

  Object.keys(properties).forEach(property => {
    const currentPropertyPath =
      parentPath !== null ? `${parentPath}.${property}` : property;
    if (typeof properties[property].type !== 'undefined') {
      propertyMap[currentPropertyPath] = properties[property].type;
      if (typeof properties[property].fields?.keyword?.type !== 'undefined') {
        propertyMap[`${currentPropertyPath}.keyword`] =
          properties[property].fields?.keyword?.type;
      }
    } else if (typeof properties[property].properties === 'object') {
      //nested object
      const nestedProperties = processEventProperties(
        currentPropertyPath,
        properties[property].properties
      );
      propertyMap = {
        ...propertyMap,
        ...nestedProperties
      };
    }
  });

  return propertyMap;
};

//idk.  like a time based cache thingy... provide a function and milliseconds to cache for.
//if we already have the results from that function from within the expiry ms, then return the prev results.
//else go get the results again, keep the cacheValue around, and reset the expiry.
const cache = (fn, ms) => {
  let expiryTime = new Date();
  let cacheValue;

  return async (...params) => {
    if (cacheValue && expiryTime > new Date()) {
      return cacheValue;
    }

    cacheValue = await fn(params);
    expiryTime.setTime(new Date().getTime() + ms);

    return cacheValue;
  };
};

export const getFlattenedEventMapping = cache(async () => {
  const indiciesToUse = getLastXIndices(2);

  try {
    const response = await authorizedFetch(
      `/api/cds-search/${indiciesToUse.join(
        ','
      )}/_mapping?ignore_unavailable=true`,
      'GET'
    );

    let flattenedMapping = {};
    Object.keys(response).forEach(indexKey => {
      flattenedMapping = {
        ...flattenedMapping,
        ...processEventProperties(null, response[indexKey].mappings.properties)
      };
    });

    return flattenedMapping;
  } catch (ex) {
    console.log(ex);
  }
}, 5 * 60 * 1000);
