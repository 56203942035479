import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../items/item.actions';

export const receiveReport = createAction('receiveReport');
export const setReportIsBusy = createAction('setReportIsBusy');
export const modifyReport = createAction('modifyReport');
export const closeReport = createAction('closeReport');

export const reportModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.REPORT,
  metrics: [],
  groups: [],
  filters: [],
  showTrend: true
});

export const getItem = getItemInternal(
  reportModel,
  receiveReport,
  setReportIsBusy,
  item => {
    //API is not peristing array is we send a 0 length array, so re-initialized the property to an empty array to keep other code cleaner
    if (!Array.isArray(item.metrics)) {
      item.metrics = [];
    }
    if (!Array.isArray(item.groups)) {
      item.groups = [];
    }
    if (!Array.isArray(item.filters)) {
      item.filters = [];
    }
  }
);
export const saveItem = saveItemInternal(modifyReport);
