import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../items/item.actions';

export const receiveDataCampaign = createAction('receiveDataCampaign');
export const setDataCampaignIsBusy = createAction('setDataCampaignIsBusy');
export const modifyDataCampaign = createAction('modifyDataCampaign');
export const closeDataCampaign = createAction('closeDataCampaign');

const dataCampaignModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.DATA_CAMPAIGN
});

export const getItem = getItemInternal(
  dataCampaignModel,
  receiveDataCampaign,
  setDataCampaignIsBusy
);
export const saveItem = saveItemInternal(modifyDataCampaign, item => {
  if (!item.advertiserId) {
    return 'Advertiser is required.';
  }
  if (!item.campaignType) {
    return 'Campaign type is required.';
  }
});
