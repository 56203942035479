// If you have to add a shade, https://elektrobild.org/tools/sort-colors will help

const whites = {
  white100: '#FFFFFF',
  white200: '#F9F9F9',
  white300: '#F3F3F3', // table active state, dropdown hover/active
  white400: '#F7F7F7', // inactive nav tab (for new campaign* structure)
  white500: '#F4F4F4'
};

const brand = {
  brand300: 'rgba(134,142,161,0.19)', // brand400 but 19% opacity. doesn't convert to hex
  brand400: '#868EA1',
  brand500: '#2A2EAC',
  brand: '#2A2EAC' // dupe for grommet
};

const border = {
  border400: '#D9D9D9', // border used for inputs
  border500: '#E9E9E9' // border used for dividing lines
};

const text = {
  text400: 'rgba(0,0,0,0.65)', // sub-header
  text500: '#4D4D4D' // header
};

const background = {
  dark: '#000',
  light: '#f3f6f8'
};

const shadows = {
  elevation500: '0px 0px 6px rgba(0,0,0,0.15)'
};

const theme = {
  name: 'fluent',
  rounding: 4,
  spacing: 20,
  global: {
    elevation: {
      dark: {},
      light: {
        none: 'none',
        ...shadows
      }
    },
    colors: {
      ...brand,
      ...whites,
      ...border,
      ...text,
      background,
      'accent-1': '#8ef6ec',
      'accent-2': '#30de3c',
      'accent-3': '#428bca',
      'accent-4': '#1ae79b',
      'accent-5': '#0052cc',
      'accent-6': '#444444'
    },
    font: {
      family:
        '-apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif',
      size: '15px',
      height: '20px',
      maxWidth: '300px'
    },
    control: {
      border: {
        radius: '4px'
      }
    },
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: '3.3333333333333335px',
      large: '10px',
      xlarge: '20px'
    },
    breakpoints: {
      small: {
        value: 640,
        borderSize: {
          xsmall: '1px',
          small: '2px',
          medium: '3.3333333333333335px',
          large: '5px',
          xlarge: '10px'
        },
        edgeSize: {
          none: '0px',
          hair: '1px',
          xxsmall: '2px',
          xsmall: '2.5px',
          small: '5px',
          medium: '10px',
          large: '20px',
          xlarge: '40px'
        },
        size: {
          xxsmall: '20px',
          xsmall: '40px',
          small: '80px',
          medium: '160px',
          large: '320px',
          xlarge: '640px',
          full: '100%'
        }
      },
      medium: {
        value: 1280
      },
      large: {}
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '2.5px',
      xsmall: '5px',
      small: '10px',
      medium: '20px',
      large: '40px',
      xlarge: '80px',
      responsiveBreakpoint: 'small'
    },
    input: {
      padding: '10px',
      weight: 600
    },
    spacing: '20px',
    size: {
      xxsmall: '40px',
      xsmall: '80px',
      small: '160px',
      medium: '320px',
      large: '640px',
      xlarge: '960px',
      xxlarge: '1280px',
      full: '100%'
    }
  },
  button: {
    default: {
      color: 'brand500',
      background: { color: 'brand300' }
    },
    primary: {
      color: 'white100',
      background: { color: 'brand500' }
    },
    secondary: {
      color: '#2F63DE',
      border: { color: '#2F63DE', width: '2px' }
    },
    hover: {
      default: { background: { color: 'brand500' } },
      secondary: { background: { color: '#2F63DE' }, color: 'white100' },
      primary: { background: { color: '#8a8cd1' }, color: 'white100' }
    },
    border: {
      radius: '4px'
    },
    padding: {
      vertical: 'xsmall'
    }
    // outline: {
    //   color: 'rgba(255,255,255,0.50)',
    //   background: 'transparent',
    //   border: `2px solid brand500`
    // }
  },
  select: {
    control: {
      extend: () => `
        border-color: ${theme.global.colors['border400']}
      `
    },
    icons: {
      margin: {
        left: 'xsmall'
      }
    }
  },
  checkBox: {
    check: {
      radius: '4px'
    },
    toggle: {
      radius: '20px',
      size: '40px'
    },
    size: '20px'
  },
  radioButton: {
    size: '20px'
  },
  calendar: {
    extend: () => `
      button {
        padding: 0;
      }
    `,
    small: {
      fontSize: '11.666666666666666px',
      lineHeight: 1.375,
      daySize: '22.857142857142858px'
    },
    medium: {
      fontSize: '15px',
      lineHeight: 1.45,
      daySize: '45.714285714285715px'
    },
    large: {
      fontSize: '25px',
      lineHeight: 1.11,
      daySize: '91.42857142857143px'
    }
  },
  clock: {
    analog: {
      hour: {
        width: '6.666666666666667px',
        size: '20px'
      },
      minute: {
        width: '3.3333333333333335px',
        size: '10px'
      },
      second: {
        width: '2.5px',
        size: '8px'
      },
      size: {
        small: '60px',
        medium: '80px',
        large: '120px',
        xlarge: '180px',
        huge: '240px'
      }
    },
    digital: {
      text: {
        xsmall: {
          size: '8.333333333333332px',
          height: 1.5
        },
        small: {
          size: '11.666666666666666px',
          height: 1.43
        },
        medium: {
          size: '15px',
          height: 1.375
        },
        large: {
          size: '18.333333333333332px',
          height: 1.167
        },
        xlarge: {
          size: '21.666666666666668px',
          height: 1.1875
        },
        xxlarge: {
          size: '28.333333333333336px',
          height: 1.125
        }
      }
    }
  },
  heading: {
    // font: {
    //   family:
    //     '-apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif'
    // },
    level: {
      '1': {
        small: {
          size: '28.333333333333336px',
          height: '33.333333333333336px',
          maxWidth: '566.6666666666667px'
        },
        medium: {
          size: '41.66666666666667px',
          height: '46.66666666666667px',
          maxWidth: '833.3333333333335px'
        },
        large: {
          size: '68.33333333333334px',
          height: '73.33333333333334px',
          maxWidth: '1366.666666666667px'
        },
        xlarge: {
          size: '95px',
          height: '100px',
          maxWidth: '1900px'
        }
      },
      '2': {
        small: {
          size: '21.666666666666668px',
          height: '26.666666666666668px',
          maxWidth: '433.33333333333337px'
        },
        medium: {
          size: '28.333333333333336px',
          height: '33.333333333333336px',
          maxWidth: '566.6666666666667px'
        },
        large: {
          size: '41.66666666666667px',
          height: '46.66666666666667px',
          maxWidth: '833.3333333333335px'
        },
        xlarge: {
          size: '55px',
          height: '60px',
          maxWidth: '1100px'
        }
      },
      '3': {
        small: {
          size: '18.333333333333332px',
          height: '23.333333333333332px',
          maxWidth: '366.66666666666663px'
        },
        medium: {
          size: '21.666666666666668px',
          height: '26.666666666666668px',
          maxWidth: '433.33333333333337px'
        },
        large: {
          size: '28.333333333333336px',
          height: '33.333333333333336px',
          maxWidth: '566.6666666666667px'
        },
        xlarge: {
          size: '35px',
          height: '40px',
          maxWidth: '700px'
        }
      },
      '4': {
        small: {
          size: '15px',
          height: '20px',
          maxWidth: '300px'
        },
        medium: {
          size: '15px',
          height: '20px',
          maxWidth: '300px'
        },
        large: {
          size: '15px',
          height: '20px',
          maxWidth: '300px'
        },
        xlarge: {
          size: '15px',
          height: '20px',
          maxWidth: '300px'
        }
      },
      '5': {
        small: {
          size: '13.333333333333334px',
          height: '18.333333333333332px',
          maxWidth: '266.6666666666667px'
        },
        medium: {
          size: '13.333333333333334px',
          height: '18.333333333333332px',
          maxWidth: '266.6666666666667px'
        },
        large: {
          size: '13.333333333333334px',
          height: '18.333333333333332px',
          maxWidth: '266.6666666666667px'
        },
        xlarge: {
          size: '13.333333333333334px',
          height: '18.333333333333332px',
          maxWidth: '266.6666666666667px'
        }
      },
      '6': {
        small: {
          size: '11.666666666666666px',
          height: '16.666666666666668px',
          maxWidth: '233.33333333333331px'
        },
        medium: {
          size: '11.666666666666666px',
          height: '16.666666666666668px',
          maxWidth: '233.33333333333331px'
        },
        large: {
          size: '11.666666666666666px',
          height: '16.666666666666668px',
          maxWidth: '233.33333333333331px'
        },
        xlarge: {
          size: '11.666666666666666px',
          height: '16.666666666666668px',
          maxWidth: '233.33333333333331px'
        }
      }
    }
  },
  paragraph: {
    small: {
      size: '11.666666666666666px',
      height: '16.666666666666668px',
      maxWidth: '233.33333333333331px'
    },
    medium: {
      size: '15px',
      height: '20px',
      maxWidth: '300px'
    },
    large: {
      size: '18.333333333333332px',
      height: '23.333333333333332px',
      maxWidth: '366.66666666666663px'
    },
    xlarge: {
      size: '21.666666666666668px',
      height: '26.666666666666668px',
      maxWidth: '433.33333333333337px'
    },
    xxlarge: {
      size: '28.333333333333336px',
      height: '33.333333333333336px',
      maxWidth: '566.6666666666667px'
    }
  },
  text: {
    xsmall: {
      size: '10px',
      height: '15px',
      maxWidth: '200px'
    },
    small: {
      size: '11.666666666666666px',
      height: '16.666666666666668px',
      maxWidth: '233.33333333333331px'
    },
    medium: {
      size: '15px',
      height: '20px',
      maxWidth: '300px'
    },
    large: {
      size: '18.333333333333332px',
      height: '23.333333333333332px',
      maxWidth: '366.66666666666663px'
    },
    xlarge: {
      size: '21.666666666666668px',
      height: '26.666666666666668px',
      maxWidth: '433.33333333333337px'
    },
    xxlarge: {
      size: '28.333333333333336px',
      height: '33.333333333333336px',
      maxWidth: '566.6666666666667px'
    }
  },
  tip: {
    content: {
      background: '#eee',
      pad: {
        vertical: 'xsmall',
        horizontal: 'small'
      },
      width: { max: '450px' }
    }
  },
  textInput: {
    extend: () => `
      border-color: ${theme.global.colors['border400']};
      &:focus {
        box-shadow: none;
        border-color: initial;
      }
    `,
    container: {
      extend: () => `
        border-color: ${theme.global.colors['border400']};
      `
    }
  },
  textArea: {
    extend: () => `
      border-color: ${theme.global.colors['border400']};
      &:focus {
        box-shadow: none;
        border-color: initial;
      }
    `
  },
  tab: {
    extend: {
      fontWeight: 'bold',
      textAlign: 'center',
      margin: 0,
      flex: 1
    },
    color: 'text500',
    border: {
      color: {
        dark: 'accent-1',
        light: 'brand300'
      },
      hover: {
        color: {
          dark: 'accent-1',
          light: 'brand400'
        }
      },
      active: {
        color: 'brand500'
      }
    }
  },
  tabs: {
    gap: 'xxsmall'
  }
};

export default theme;
