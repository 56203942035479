import {
  modifyReward,
  receiveReward,
  setRewardIsBusy,
  closeReward
} from './reward.actions';
import itemReducer from '../items/item.reducers';

const rewardReducer = itemReducer(
  receiveReward,
  setRewardIsBusy,
  modifyReward,
  closeReward
);

export default rewardReducer;
