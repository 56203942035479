import {
  modifyBlock,
  receiveBlock,
  setBlockIsBusy,
  closeBlock
} from './content-block.actions';
import itemReducer from '../items/item.reducers';

const contentBlockReducer = itemReducer(
  receiveBlock,
  setBlockIsBusy,
  modifyBlock,
  closeBlock
);

export default contentBlockReducer;
