import React from 'react';
import { Box } from 'grommet';

const Loader = () => (
  <Box flex={true} align='center' justify='center'>
    <Box className='loader-dots'></Box>
  </Box>
);

export default Loader;
