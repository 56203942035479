import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../items/item.actions';

export const receiveCampaignPlacement = createAction(
  'receiveCampaignPlacement'
);
export const setCampaignPlacementIsBusy = createAction(
  'setCampaignPlacementIsBusy'
);
export const modifyCampaignPlacement = createAction('modifyCampaignPlacement');
export const closeCampaignPlacement = createAction('closeCampaignPlacement');

export const CampaignPlacementModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.CAMPAIGN_PLACEMENT
});

export const getItem = getItemInternal(
  CampaignPlacementModel,
  receiveCampaignPlacement,
  setCampaignPlacementIsBusy
);
export const saveItem = saveItemInternal(modifyCampaignPlacement, item => {});
