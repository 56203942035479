import {
  modifyIntegration,
  receiveIntegration,
  setIntegrationIsBusy,
  closeIntegration
} from './integration.actions';
import itemReducer from '../../items/item.reducers';

const integrationReducer = itemReducer(
  receiveIntegration,
  setIntegrationIsBusy,
  modifyIntegration,
  closeIntegration
);

export default integrationReducer;
