import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../items/item.actions';

export const receivePage = createAction('receivePage');
export const setPageIsBusy = createAction('setPageIsBusy');
export const modifyPage = createAction('modifyPage');
export const closePage = createAction('closePage');

export const pageModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.PAGE,
  pageDefinition: {}
});

export const getItem = getItemInternal(pageModel, receivePage, setPageIsBusy);
export const saveItem = saveItemInternal(modifyPage);
