import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../../items/item.actions';

export const receiveDataDeliveryGroup = createAction(
  'receiveDataDeliveryGroup'
);
export const setDataDeliveryGroupIsBusy = createAction(
  'setDataDeliveryGroupIsBusy'
);
export const modifyDataDeliveryGroup = createAction('modifyDataDeliveryGroup');
export const closeDataDeliveryGroup = createAction('closeDataDeliveryGroup');

const dataDeliveryGroupModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.DATA_DELIVERY_GROUP,
  deliveryPriority: 'EPR',
  deliveries: []
});

export const getItem = getItemInternal(
  dataDeliveryGroupModel,
  receiveDataDeliveryGroup,
  setDataDeliveryGroupIsBusy
);
export const saveItem = saveItemInternal(
  modifyDataDeliveryGroup,
  item => {},
  item => {
    if (
      item.deliveries &&
      Array.isArray(item.deliveries) &&
      item.deliveries.length
    ) {
      item.deliveries = item.deliveries.map(d => ({
        id: d.id,
        order: d.order
      }));
    }
  }
);
