import { connect } from 'react-redux';
import { removeToast } from './toasts.actions';
import Toasts from './toasts';

const mapStateToProps = state => ({
  items: state.toasts.items
});

const mapDispatchToProps = {
  removeToast
};

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
