import {
  modifyDataCampaign,
  receiveDataCampaign,
  setDataCampaignIsBusy,
  closeDataCampaign
} from '../data/data-campaign.actions';
import itemReducer from '../../items/item.reducers';

const dataCampaignReducer = itemReducer(
  receiveDataCampaign,
  setDataCampaignIsBusy,
  modifyDataCampaign,
  closeDataCampaign
);

export default dataCampaignReducer;
