import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../items/item-types';
import { authorizedFetch } from '../utils.js';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../items/item.actions';

export const receiveSurvey = createAction('receiveSurvey');
export const setSurveyIsBusy = createAction('setSurveyIsBusy');
export const modifySurvey = createAction('modifySurvey');
export const closeSurvey = createAction('closeSurvey');

const surveyModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.SURVEY,
  surveyJson: ''
});

export const getItem = getItemInternal(
  surveyModel,
  receiveSurvey,
  setSurveyIsBusy
);
export const saveItem = saveItemInternal(modifySurvey);

export const getCustomAttributes = () => {
  return new Promise((resolve, reject) => {
    authorizedFetch(`/api/userattribute`)
      .then(response => {
        resolve(response.items);
      })
      .catch(err => {
        reject(err);
      });
  });
};
